import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";
import { v4 } from "uuid";
import CobeeButton from "../../../app/components/CobeeButton";
import useStyles, { CustomCheckbox } from "./TransferTransactionSection/styles";

import {
  findEmployeeAvailablePursesAmount,
  findEmployeeAvailableBagAmount,
  findEmployeePayrollCycles,
} from "../../../infrastructure/finance-repository";
import { MonetaryAmount } from "../../../app/domain/monetary-amount";
import CobeeTextField from "../../../app/components/CobeeTextField";
import { showErrorMessage } from "../../commons/show-error-message";

import generateManualTransaction from "../generate-manual-transaction";
import { findEmployeesByEmail } from "../../../infrastructure/employee-repository";
import CobeeDatePicker from "../../../app/components/CobeeDatePicker";
import { TransactionCategory } from "../../../app/domain/transaction-category";
import { TransactionType } from "../../../app/domain/transaction-type";

export default function ManualTransactionSection({ searchValues }) {
  const { row, select, currencyContainer, divider } = useStyles();

  const [selectedDate, setSelectedDate] = useState();
  const [currency, setCurrency] = useState("EUR");

  const [formData, setFormData] = useState({
    email: searchValues?.email || "",
    numMp: searchValues?.numMp || "",
    employeeId: "",
    password: "",
    category: "",
    description: "",
    payrollId: "",
    totalAmount: MonetaryAmount.zeroWithCurrency(currency),
    authorizationId: "",
    processorTransactionId: "",
    recurrentPaymentId: "",
    behaviours: [
      {
        benefitBag: false,
        purse: false,
        behaviour: "flex",
        amount: MonetaryAmount.zeroWithCurrency(currency),
      },
      {
        benefitBag: false,
        purse: false,
        behaviour: "flex-not-exempt",
        amount: MonetaryAmount.zeroWithCurrency(currency),
      },
      {
        benefitBag: false,
        purse: false,
        behaviour: "allowance",
        amount: MonetaryAmount.zeroWithCurrency(currency),
      },
      {
        benefitBag: false,
        purse: false,
        behaviour: "allowance-not-exempt",
        amount: MonetaryAmount.zeroWithCurrency(currency),
      },
    ],
  });

  const [benefits, setBenefits] = useState([]);
  const [employee, setEmployee] = useState(undefined);
  const [purses, setPurses] = useState({});
  const [availableBagAmount, setAvailableBagAmount] = useState(
    MonetaryAmount.ZERO
  );
  const [pCycles, setPCycles] = useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const handleSearch = useCallback(
    async ({ override }) => {
      setIsLoading(true);
      setBenefits(undefined);
      setEmployee(undefined);
      setCurrency("EUR");
      setPurses({
        flex: MonetaryAmount.ZERO,
        flexNotExempt: MonetaryAmount.ZERO,
        allowance: MonetaryAmount.ZERO,
        allowanceNotExempt: MonetaryAmount.ZERO,
      });
      setAvailableBagAmount(MonetaryAmount.ZERO);
      setPCycles(undefined);

      setFormData({ ...formData, employeeId: "", category: "" });

      const { email, numMp } = formData;
      const data = await findEmployeesByEmail(
        override ? searchValues.email : email,
        override ? searchValues.numMp : numMp
      );
      if (data.length > 0) {
        let {
          benefitModel: { allowedBenefits },
        } = data[0];
        allowedBenefits = allowedBenefits.filter(
          (benefit) => benefit !== "gift-card-benefit"
        );
        setBenefits(allowedBenefits);
        setEmployee(data[0].employee);
        const zero = MonetaryAmount.zeroWithCurrency(currency);
        setPurses({
          flex: zero,
          flexNotExempt: zero,
          allowance: zero,
          allowanceNotExempt: zero,
        });
        setAvailableBagAmount(zero);
        setFormData({ ...formData, employeeId: data[0].employee.id });

        const {
          data: { payrollCycles },
        } = await findEmployeePayrollCycles({
          employeeId: data[0].employee.id,
        });
        setPCycles(payrollCycles);
      } else {
        showErrorMessage("Employee not found");
      }

      setIsLoading(false);
    },
    [currency, formData, searchValues]
  );
  const formatLabel = (time) => {
    if (time === null) {
      return "Current payroll";
    }
    if (time === -1) {
      return "No payroll";
    }
    return moment.unix(time).format("MMM YYYY");
  };
  const getAvailablePursesAmount = async (category) => {
    const employeePurses = await findEmployeeAvailablePursesAmount({
      employeeId: employee.id,
      category,
    });
    if (employeePurses) {
      setCurrency(employeePurses.allowance.currency);
      const allowance = MonetaryAmount.ofCurrency(employeePurses.allowance);
      const allowanceNotExempt = MonetaryAmount.ofCurrency(
        employeePurses["allowance-not-exempt"]
      );
      const flex = MonetaryAmount.ofCurrency(employeePurses.flex);
      const flexNotExempt = MonetaryAmount.ofCurrency(
        employeePurses["flex-not-exempt"]
      );

      setPurses({
        flex,
        flexNotExempt,
        allowance,
        allowanceNotExempt,
      });
      const {
        data: { available: bagAmount },
      } = await findEmployeeAvailableBagAmount({
        employeeId: employee.id,
        category,
      });
      setAvailableBagAmount(bagAmount);
    } else {
      showErrorMessage("Purses not found");
    }
  };

  const mapType = {
    [TransactionCategory.CLOTHING]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.EDUCATION_BENEFIT]: TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.GAS]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.GROCERIES]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.GYM_BENEFIT]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.HEALTH_INSURANCE_BENEFIT]:
      TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.HOME_OFFICE]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.LIFE_INSURANCE_BENEFIT]:
      TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.MEAL_BENEFIT]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.NURSERY_BENEFIT]: TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.PENSION_PLAN]: TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.RENTING_BENEFIT]: TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.RESTAURANTS]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.RETIREMENT_INSURANCE]:
      TransactionType.SCHEDULED_PURCHASE,
    [TransactionCategory.TRANSPORT_BENEFIT]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.UNKNOWN]: TransactionType.CARD_PURCHASE,
    [TransactionCategory.WELLNESS_BENEFIT]: TransactionType.SCHEDULED_PURCHASE,
  };
  const setCategory = (e) => {
    const type = mapType[e.target.value];
    setFormData({ ...formData, category: e.target.value, type });
    getAvailablePursesAmount(e.target.value);
  };

  const handleCheckbox = ({ behaviour, purse, benefitBag }) => {
    setFormData({
      ...formData,
      behaviours: formData.behaviours.map((item) => {
        if (item.behaviour === behaviour) {
          return {
            ...item,
            purse,
            benefitBag,
          };
        }
        return item;
      }),
    });
  };
  const handleAmountChange = (newAmount, behaviour) => {
    setFormData({
      ...formData,
      behaviours: formData.behaviours.map((item) => {
        if (item.behaviour === behaviour) {
          return {
            ...item,
            amount: MonetaryAmount.fromUnitary(Number(newAmount), currency),
          };
        }
        return item;
      }),
    });
  };
  useEffect(() => {
    if (searchValues && !isLoading) {
      handleSearch({ override: true });
    }
  }, [searchValues, handleSearch, isLoading]);

  return (
    <main className="container-fluid pb-3">
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">Employee</h5>
          <p className="card-text">Search employee</p>
          <div className="form-group row">
            <Grid item container xs={12} alignItems="center" className={row}>
              <Grid item xs={4} className="mr-2">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4} className="mr-2">
                <input
                  type="numMP"
                  className="form-control"
                  placeholder="numMp"
                  value={formData.numMp}
                  onChange={(e) =>
                    setFormData({ ...formData, numMp: e.target.value })
                  }
                />
              </Grid>
              <CobeeButton
                isLoading={isLoading}
                disabled={!formData.email && !formData.numMp}
                onClick={() => handleSearch({})}
              >
                Search
              </CobeeButton>
            </Grid>
          </div>

          {employee && (
            <>
              <Grid item xs={12} />

              <Grid item xs={12} className={divider} />

              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid item container xs={2} className={row}>
                    <Typography variant="body2">Category:</Typography>
                  </Grid>

                  <Grid item container xs={8} className={row}>
                    <Select
                      className={select}
                      onChange={setCategory}
                      disabled={!benefits}
                      value={formData.category}
                    >
                      {benefits &&
                        benefits.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          {formData.category && purses && (
            <>
              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid item container xs={2} className={row}>
                    <Typography variant="body2">Available:</Typography>
                  </Grid>
                  <Grid item container xs={3} className={row}>
                    <Typography variant="body2">
                      Bag:{" "}
                      <b>
                        {availableBagAmount
                          ? MonetaryAmount.ofCurrency(
                              availableBagAmount
                            ).prettifyMoney({})
                          : "-"}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container xs={12} className={row}>
                  <Grid item container xs={2} className={row} />
                  <Grid item container xs={3} className={row}>
                    <Typography variant="body2">
                      Flex:{" "}
                      <b>{purses.flex ? purses.flex.prettifyMoney({}) : "-"}</b>
                    </Typography>
                  </Grid>

                  <Grid item container xs={3} className={row}>
                    <Typography variant="body2">
                      Flex not exempt:{" "}
                      <b>
                        {purses.flexNotExempt
                          ? purses.flexNotExempt.prettifyMoney({})
                          : "-"}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} className={row}>
                  <Grid item container xs={2} className={row} />
                  <Grid item container xs={3} className={row}>
                    <Typography variant="body2">
                      Allowance:{" "}
                      <b>
                        {purses.allowance
                          ? purses.allowance.prettifyMoney({})
                          : "-"}
                      </b>
                    </Typography>
                  </Grid>

                  <Grid item container xs={3} className={row}>
                    <Typography variant="body2">
                      Allowance not exempt:{" "}
                      <b>
                        {purses.allowanceNotExempt
                          ? purses.allowanceNotExempt.prettifyMoney({})
                          : "-"}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid item container xs={2} className={row}>
                    <Typography variant="body2">Payroll Cycle:</Typography>
                  </Grid>
                  <Grid item container xs={8} className={row}>
                    <Select
                      className={select}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          payrollId: e.target.value,
                        })
                      }
                      disabled={!pCycles}
                      value={formData.payrollId}
                    >
                      {pCycles &&
                        pCycles.map(({ endTimestamp, id }) => (
                          <MenuItem key={v4()} value={id}>
                            {formatLabel(endTimestamp)}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </div>

              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    className={row}
                  >
                    <Grid item xs={2} container alignItems="center">
                      <Typography variant="body2">Transaction Id:</Typography>
                    </Grid>
                    <Grid item xs={10} className="mr-2">
                      <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CobeeTextField
                          type="string"
                          minWidth="100%"
                          min={0}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              transactionId: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid item container xs={2} className={row}>
                    <Typography variant="body2">Date:</Typography>
                  </Grid>

                  <Grid item container xs={8} className={row}>
                    <CobeeDatePicker
                      selectedDate={selectedDate}
                      maxDate={new Date()}
                      showTimeSelect
                      timeIntervals={15}
                      onChange={(e) => {
                        setSelectedDate(e);
                        setFormData({
                          ...formData,
                          date: moment(e).format("YYYY-MM-DDTHH:mm:ssZ"),
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    className={row}
                  >
                    <Grid item xs={2} container alignItems="center">
                      <Typography variant="body2">
                        Transaction Concept:
                      </Typography>
                    </Grid>
                    <Grid item xs={10} className="mr-2">
                      <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CobeeTextField
                          type="string"
                          minWidth="100%"
                          min={0}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    className={row}
                  >
                    <Grid item xs={2} container alignItems="center">
                      <Typography variant="body2">
                        Provider Authorization ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={10} className="mr-2">
                      <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CobeeTextField
                          type="string"
                          minWidth="100%"
                          min={0}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              authorizationId: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    className={row}
                  >
                    <Grid item xs={2} container alignItems="center">
                      <Typography variant="body2">
                        Provider Transaction ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={10} className="mr-2">
                      <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CobeeTextField
                          type="string"
                          minWidth="100%"
                          min={0}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              processorTransactionId: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className="form-group row">
                <Grid item container xs={12} className={row}>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    className={row}
                  >
                    <Grid item xs={2} container alignItems="center">
                      <Typography variant="body2">
                        Recurrent Payment ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={10} className="mr-2">
                      <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CobeeTextField
                          type="string"
                          minWidth="100%"
                          min={0}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              recurrentPaymentId: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              {formData.behaviours.map(
                ({ behaviour, amount, benefitBag, purse }) => (
                  <div className="form-group row" key={behaviour}>
                    <Grid item container xs={12} className={row}>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        className={row}
                      >
                        <Grid item xs={2} container alignItems="center">
                          <Typography variant="body2">{behaviour}:</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={behaviour.includes("allowance") ? 3 : 6}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <CobeeTextField
                            type="number"
                            defaultValue={MonetaryAmount.ofCurrency(
                              amount
                            ).toUnitary()}
                            minWidth="100%"
                            min={0}
                            onChange={(e) =>
                              handleAmountChange(e.target.value, behaviour)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className={currencyContainer}
                          container
                          alignContent="center"
                        >
                          <Typography variant="body2">{currency}</Typography>
                        </Grid>
                        {behaviour.includes("allowance") && (
                          <Grid
                            item
                            container
                            xs={3}
                            alignItems="center"
                            justify="flex-end"
                          >
                            <FormControlLabel
                              control={
                                <CustomCheckbox
                                  checked={benefitBag}
                                  onChange={() =>
                                    handleCheckbox({
                                      behaviour,
                                      benefitBag: !benefitBag,
                                      purse: false,
                                    })
                                  }
                                  disabled={MonetaryAmount.ofCurrency(
                                    availableBagAmount,
                                    currency
                                  ).equal(
                                    MonetaryAmount.zeroWithCurrency(currency)
                                  )}
                                />
                              }
                              label="From benefit bag"
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          container
                          xs={3}
                          alignItems="center"
                          justify="flex-end"
                        >
                          <FormControlLabel
                            control={
                              <CustomCheckbox
                                checked={purse}
                                onChange={() =>
                                  handleCheckbox({
                                    behaviour,
                                    benefitBag: false,
                                    purse: !purse,
                                  })
                                }
                                disabled={
                                  purses[behaviour]
                                    ? MonetaryAmount.ofCurrency(
                                        purses[behaviour]
                                      ).equal(
                                        MonetaryAmount.zeroWithCurrency(
                                          currency
                                        )
                                      )
                                    : true
                                }
                              />
                            }
                            label="From purses"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )
              )}
              <CobeeButton
                isLoading={isLoading}
                disabled={!formData.description.trim() || !formData.payrollId}
                onClick={() =>
                  generateManualTransaction({
                    employee,
                    category: formData.category,
                    type: formData.type,
                    currency,
                    searchValues: formData,
                  })
                }
              >
                Execute Transaction
              </CobeeButton>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
