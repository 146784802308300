import React from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import CobeeTextField from "app/components/CobeeTextField";
import CobeeDatePicker from "app/components/CobeeDatePicker";
import { regexConfig } from "infrastructure/regexTester";
import useStyles from "./styles";
import parentStyles from "../../styles";

export default function DefaultLanguageRetirementForm({
  product,
  onChangeTabName,
  brokers,
  vendors,
  isEmptyProduct,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { subTitle, datePickerInput, errorCaption } = useStyles();
  const { fullWidth } = parentStyles();

  const getProductError = ({ key }) => {
    let value = errors;
    key.split(".").forEach((attribute) => {
      value = value?.[attribute];
    });

    return value?.message ? (
      <FormHelperText className={errorCaption}>{value.message}</FormHelperText>
    ) : null;
  };

  return (
    <>
      {product?.id && (
        <Grid container item alignItems="center">
          <Grid item xs={2}>
            <Typography variant="body2">Product id</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{product.id}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body2">Product status</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                edge="start"
                onChange={onChange}
                color="primary"
                disabled={isEmptyProduct}
              />
            )}
            name="enabled"
            control={control}
            defaultValue={product?.enabled}
          />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Product name</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeTextField
                type="text"
                minWidth={220}
                className={fullWidth}
                defaultValue={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  onChangeTabName(e.target.value);
                }}
              />
            )}
            name="en.name"
            control={control}
            rules={{ required: "Field is required" }}
            defaultValue={product?.languages?.en?.name || ""}
          />
          {getProductError({
            key: "en.name",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Broker</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                id="broker-select"
                variant="outlined"
                value={value}
                className={fullWidth}
                onChange={(e) => onChange(e.target.value)}
              >
                <MenuItem value={undefined} disabled>
                  Select a broker
                </MenuItem>

                {brokers.map(({ id: typeId, name: typeName }) => (
                  <MenuItem key={typeId} value={typeName}>
                    {typeName}
                  </MenuItem>
                ))}
              </Select>
            )}
            name="broker"
            control={control}
            rules={{ required: "Field is required" }}
            defaultValue={product?.broker || ""}
          />
          {getProductError({
            key: "broker",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Insurance company</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                id="vendor-select"
                variant="outlined"
                value={value}
                className={fullWidth}
                onChange={(e) => onChange(e.target.value)}
              >
                <MenuItem value={undefined} disabled>
                  Select a vendor
                </MenuItem>

                {vendors.map(({ id: typeId, name: typeName }) => (
                  <MenuItem key={typeId} value={typeId}>
                    {typeName}
                  </MenuItem>
                ))}
              </Select>
            )}
            name="vendorId"
            control={control}
            rules={{ required: "Field is required" }}
            defaultValue={product?.vendorId || ""}
          />
          {getProductError({
            key: "vendorId",
          })}
        </Grid>
      </Grid>

      <Grid container item alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body2">
            Pension commitment (The employee can cancel the subscription at
            anytime)
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                edge="start"
                onChange={onChange}
                color="primary"
                disabled={isEmptyProduct}
              />
            )}
            name="pensionCommitment"
            control={control}
            defaultValue={product?.pensionCommitment}
          />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">
            Minimum recurrent contribution
          </Typography>
          <Typography className={subTitle}>0,00€ by default</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeTextField
                type="number"
                min={0}
                step="0.01"
                minWidth={220}
                defaultValue={value}
                className={fullWidth}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            name="minRecurrentContribution"
            control={control}
            rules={{
              required: "Field is required",
              validate: (value) => {
                return value >= 0 || "The minimum quantity is 0EUR";
              },
            }}
            defaultValue={product?.minRecurrentContribution || 0}
          />
          {getProductError({
            key: "minRecurrentContribution",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Minimum punctual contribution</Typography>

          <Typography className={subTitle}>0,00€ by default</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeTextField
                type="number"
                min={0}
                step="0.01"
                minWidth={220}
                defaultValue={value}
                className={fullWidth}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            name="minPunctualContribution"
            control={control}
            rules={{
              required: "Field is required",
              validate: (value) => {
                return value >= 0 || "The minimum quantity is 0EUR";
              },
            }}
            defaultValue={product?.minPunctualContribution || 0}
          />
          {getProductError({
            key: "minPunctualContribution",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Insurance policy</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeTextField
                type="text"
                minWidth={220}
                className={fullWidth}
                defaultValue={value}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            name="insurancePolicy"
            rules={{ required: "Field is required" }}
            control={control}
            defaultValue={product?.insurancePolicy || ""}
          />
          {getProductError({
            key: "insurancePolicy",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Description</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextField
                defaultValue={value}
                multiline
                rows={5}
                variant="outlined"
                className={fullWidth}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            name="en.description"
            rules={{ required: "Field is required" }}
            control={control}
            defaultValue={product?.languages?.en?.description || ""}
          />
          {getProductError({
            key: "en.description",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Brochure link</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeTextField
                type="text"
                minWidth={220}
                defaultValue={value}
                className={fullWidth}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            rules={{
              required: "Field is required",
              pattern: {
                value: new RegExp(regexConfig.url.regex),
                message: "Invalid url",
              },
            }}
            name="en.attachments.url"
            control={control}
            defaultValue={product?.languages?.en?.attachments?.url || ""}
          />
          {getProductError({
            key: "en.attachments.url",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Brochure title</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeTextField
                type="text"
                minWidth={220}
                className={fullWidth}
                defaultValue={value}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
            name="en.attachments.title"
            control={control}
            rules={{ required: "Field is required" }}
            defaultValue={product?.languages?.en?.attachments?.title || ""}
          />
          {getProductError({
            key: "en.attachments.title",
          })}
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={2}>
          <Typography variant="body2">Availability Date</Typography>
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CobeeDatePicker
                fullWidth
                value={value}
                onChange={(e) => onChange(e)}
                customInput={
                  <div className={datePickerInput}>
                    {moment(value).format("DD-MM-YYYY")}
                  </div>
                }
              />
            )}
            name="availabilityDate"
            control={control}
            defaultValue={product?.availabilityDate || new Date()}
          />
        </Grid>
      </Grid>
    </>
  );
}

DefaultLanguageRetirementForm.propTypes = {
  product: PropTypes.object.isRequired,
  onChangeTabName: PropTypes.func.isRequired,
  brokers: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  isEmptyProduct: PropTypes.bool.isRequired,
};
