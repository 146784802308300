import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import NurseryList from "../NurseryList";
import NurseriesRequests from "../NurseriesRequests";
import NurseriesActivePayments from "../NurseriesActivePayments";
import { findNurseries } from "../../../../infrastructure/nursery-repository";
import { showErrorMessage } from "../../../commons/show-error-message";
import { editNursery } from "../../edit-nursery";
import { fetchNurseriesRequests } from "../../fetch-nurseries-requests";
import TabPanel from "../../../../app/components/TabPanel";
import { fetchNurseriesActivePayments } from "../../fetch-nurseries-active-payments";

export default function NurseriesPageV2() {
  const [currentTab, setCurrentTab] = useState(0);
  const [nurseriesData, setNurseriesData] = useState([]);
  const [nurseriesRequests, setNurseriesRequests] = useState([]);
  const [activePayments, setActivePayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingRequests, setIsFetchingRequests] = useState(true);
  const [isFetchingNurseries, setIsFetchingNurseries] = useState(false);
  const [isFetchingActivePayment, setIsFetchingActivePayment] = useState(false);
  const [dateFilter, setDateFilter] = useState(new Date());

  useEffect(() => {
    setIsFetchingActivePayment(true);
  }, [dateFilter, setIsFetchingActivePayment]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setIsLoading(true);
        const requests = await fetchNurseriesRequests();
        setNurseriesRequests(requests);
      } catch (error) {
        showErrorMessage("There was an error loading the nurseries requests");
      } finally {
        setIsLoading(false);
        setIsFetchingRequests(false);
      }
    };

    if (isFetchingRequests && currentTab === 0) {
      fetchRequests();
    }
  }, [isFetchingRequests, currentTab]);

  useEffect(() => {
    const fetchNurseries = async () => {
      try {
        setIsLoading(true);
        const { data } = await findNurseries();
        setNurseriesData(data);
      } catch (error) {
        showErrorMessage("There was an error loading the nurseries list");
      } finally {
        setIsLoading(false);
        setIsFetchingNurseries(false);
      }
    };

    if (isFetchingNurseries && currentTab === 1) {
      fetchNurseries();
    }
  }, [isFetchingNurseries, currentTab]);

  useEffect(() => {
    const fetchActivePayments = async () => {
      try {
        setIsLoading(true);
        const activePaymentsData = await fetchNurseriesActivePayments({
          filters: { month: moment(dateFilter).format("YYYY-MM") },
        });
        setActivePayments(activePaymentsData);
      } catch (error) {
        showErrorMessage("There was an error loading the active payments");
      } finally {
        setIsLoading(false);
        setIsFetchingActivePayment(false);
      }
    };

    if (isFetchingActivePayment && currentTab === 2) {
      fetchActivePayments();
    }
  }, [dateFilter, isFetchingActivePayment, currentTab]);

  const { container } = useStyles();

  const handleChange = (_event, value) => {
    if (value === 0) {
      setIsFetchingRequests(true);
    } else if (value === 1) {
      setIsFetchingNurseries(true);
    } else if (value === 2) {
      setIsFetchingActivePayment(true);
    }
    setCurrentTab(value);
  };

  const updateState = async ({ nurseryId, ...rest }) => {
    await editNursery({ nurseryId, ...rest });
    if (currentTab === 0) {
      setIsFetchingRequests(true);
    } else if (currentTab === 1) {
      setIsFetchingNurseries(true);
    }
  };

  return (
    <main className="container-fluid">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title buttonRight">Nurseries</h5>

          <p>
            The nurseries management must now be done by the{" "}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://cobee.retool.com/apps/d7b85ee4-327e-11ed-a403-93f0a3fa2df8/Benefits/nursery-home"
              rel="noopener"
              target="_blank"
            >
              new backoffice
            </a>
          </p>
        </div>
      </div>
    </main>
  );
}
