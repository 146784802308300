export const TransactionCategory = {
  CLOTHING: "clothing-benefit",
  EDUCATION_BENEFIT: "education-benefit",
  GAS: "gas-benefit",
  GIFT_CARD_BENEFIT: "gift-card-benefit",
  GROCERIES: "groceries-benefit",
  GYM_BENEFIT: "gym-benefit",
  HEALTH_INSURANCE_BENEFIT: "health-insurance-benefit",
  HOME_OFFICE: "home-office-benefit",
  LIFE_INSURANCE_BENEFIT: "life-insurance-benefit",
  MEAL_BENEFIT: "meal-benefit",
  NURSERY_BENEFIT: "nursery-benefit",
  PENSION_PLAN: "pension-plan-benefit",
  RENTING_BENEFIT: "renting-benefit",
  RESTAURANTS: "restaurants-benefit",
  RETIREMENT_INSURANCE: "retirement-insurance-benefit",
  TRANSPORT_BENEFIT: "transport-benefit",
  UNKNOWN: "unknown",
  WELLNESS_BENEFIT: "wellness-benefit",
};

export const getAllRecurrentPaymentTransactionCategories = () => [
  TransactionCategory.NURSERY_BENEFIT,
  TransactionCategory.EDUCATION_BENEFIT,
  TransactionCategory.HEALTH_INSURANCE_BENEFIT,
  TransactionCategory.RENTING_BENEFIT,
  TransactionCategory.LIFE_INSURANCE_BENEFIT,
  TransactionCategory.WELLNESS_BENEFIT,
  TransactionCategory.RETIREMENT_INSURANCE,
  TransactionCategory.PENSION_PLAN,
];

export const getAllTransactionCategories = () =>
  Object.values(TransactionCategory);
export const getCardTransactionCategories = () => [
  TransactionCategory.MEAL_BENEFIT,
  TransactionCategory.TRANSPORT_BENEFIT,
  TransactionCategory.UNKNOWN,
  TransactionCategory.GAS,
  TransactionCategory.GROCERIES,
  TransactionCategory.RESTAURANTS,
  TransactionCategory.HOME_OFFICE,
];

export const getAccumulableTransactionCategories = () => [
  TransactionCategory.MEAL_BENEFIT,
  TransactionCategory.TRANSPORT_BENEFIT,
  TransactionCategory.GAS,
  TransactionCategory.GROCERIES,
  TransactionCategory.RESTAURANTS,
  TransactionCategory.HOME_OFFICE,
];
