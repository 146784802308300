import { getDeliveriesEmployeeBehaviors } from "infrastructure/get-deliveries-employee-behaviours-repository";
import { getDeliveriesBenefitsAvailableForEmployees } from "infrastructure/get-deliveries-employee-benefits-available";
import React, { Component } from "react";
import { format } from "date-fns";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  createDeliveriesRequest,
  findEmployeesByEmail,
} from "../../../../infrastructure/employee-repository";
import { findEmployeePayrollCycles } from "../../../../infrastructure/finance-repository";
import { showErrorMessage } from "../../../commons/show-error-message";
import { showSuccessMessage } from "../../../commons/show-success-message";
import CobeeDatePicker from "../../../../app/components/CobeeDatePicker";
import styles from "./styles";

class DeliveriesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      concept: "",
      numMp: "",
      payrollId: "",
      behaviour: "",
      category: "",
      employeeId: "",
      companyName: "",
      password: "",
      amount: 0,
      employees: [],
      payrollCycles: [],
      selectedDate: null,
      behaviours: [],
      categories: [],
    };
  }

  resetSearchedValues = () => {
    this.setState({
      payrollId: "",
      behaviour: "",
      category: "",
      employeeId: "",
      companyName: "",
      password: "",
      amount: 0,
      employees: [],
      payrollCycles: [],
      selectedDate: null,
    });
  };

  async handleSearch() {
    this.resetSearchedValues();
    const { email, numMp } = this.state;
    this.setState({ loading: true });
    try {
      const data = await findEmployeesByEmail(email, numMp);
      if (data.length > 1) {
        showErrorMessage("Duplicated email -> Select correct id");
      }
      let employees = [];
      data.map(({ employee, company }) => {
        const { id, companyId, name, surname, state, regionalInfo } = employee;
        const { companyName } = company;
        employees = [
          ...employees,
          {
            id,
            companyId,
            companyName,
            name,
            surname,
            state,
            regionalInfo,
          },
        ];
        return employees;
      });
      const employee = employees.length === 1 ? employees[0] : "";
      this.setState({
        employees,
        employee,
        employeeId: employee.id,
      });

      if (employees.length === 1) {
        this.loadPayrollCycles(employees[0].id);
      }
      const deliveriesEmployeeBehaviors = await getDeliveriesEmployeeBehaviors(
        employee.id
      );

      this.setState({ behaviours: deliveriesEmployeeBehaviors });
      const deliveriesEmployeeAvailableBenefits = await getDeliveriesBenefitsAvailableForEmployees(
        employee.id
      );
      this.setState({ categories: deliveriesEmployeeAvailableBenefits });
      showSuccessMessage("Employee found");
    } catch (error) {
      this.resetSearchedValues();
      this.setState({ loading: false });
      return showErrorMessage("Employee not found");
    }
    return this.setState({ loading: false });
  }

  async loadPayrollCycles(employeeId) {
    const {
      data: { payrollCycles, companyName },
    } = await findEmployeePayrollCycles({ employeeId });

    this.setState({ payrollCycles, companyName });
  }

  async executeDelivery() {
    this.setState({ loading: true });
    const {
      payrollId,
      password,
      behaviour,
      amount,
      category,
      selectedDate,
      concept,
      employee,
    } = this.state;
    const amountInCents = +(amount * 100).toFixed(0);

    const formattedDate = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ssxxx");
    const isSpecificCategory =
      category.includes("-benefit") || category === "unknown";

    const { data } = await createDeliveriesRequest(employee.id, {
      payrollId,
      password,
      concept,
      behaviour: {
        behaviour,
        amount: {
          amountInCents,
          currency:
            employee.regionalInfo.countryISO2Code === "MX" ? "MXN" : "EUR",
        },
        benefitBag: false,
        purse: false,
      },
      category: isSpecificCategory ? category : "custom-benefit",
      subCategoryId: isSpecificCategory ? undefined : category,
      date: formattedDate,
    });

    if (data?.success) {
      this.resetSearchedValues();
      showSuccessMessage("success delivery");
    } else {
      showErrorMessage("Wrong. Some Data are not correct");
    }
    this.setState({ loading: false });
  }

  formatLabel = (time) => {
    if (time === null) {
      return "Current payroll";
    }
    if (time === -1) {
      return "No payroll";
    }

    return format(new Date(time * 1000), "MMM yyyy");
  };

  getPickerDates = (startTimestamp, endTimestamp) => {
    if (startTimestamp === -1 && endTimestamp === -1) {
      return {
        maxDate: new Date(),
        minDate: null,
      };
    }
    return {
      maxDate: endTimestamp ? new Date(endTimestamp * 1000) : new Date(),
      minDate: startTimestamp ? new Date(startTimestamp * 1000) : null,
    };
  };

  render() {
    const {
      loading,
      password,
      employeeId,
      companyName,
      employees,
      payrollId,
      behaviour,
      category,
      amount,
      email,
      numMp,
      payrollCycles,
      selectedDate,
      concept,
      behaviours,
      categories,
      endTimestamp,
      startTimestamp,
    } = this.state;

    const {
      classes: { row },
    } = this.props;

    return (
      <main className="container-fluid">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Deliveries Request</h5>
            <p className="card-text" />

            <div className="form-group row">
              <div className="col-sm-6 mb-2">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <input
                  type="numMP"
                  className="form-control"
                  placeholder="numMp"
                  value={numMp}
                  onChange={(e) => this.setState({ numMp: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <button
                  className="btn btn-primary"
                  disabled={(!email && !numMp) || loading}
                  onClick={() => this.handleSearch()}
                >
                  Search
                </button>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-3 mb-2">
                <select
                  className="custom-select"
                  value={payrollId}
                  disabled={payrollCycles.length === 0}
                  onChange={(e) => {
                    const newPayrollId = e.target.value;
                    const selectedPayroll = payrollCycles.find(
                      (payroll) => payroll.id === newPayrollId
                    );
                    this.setState({
                      payrollId: newPayrollId,
                      selectedDate: null,
                      endTimestamp: selectedPayroll?.endTimestamp,
                      startTimestamp: selectedPayroll?.startTimestamp,
                    });
                  }}
                >
                  <option defaultValue>Select Payroll</option>
                  {payrollCycles.map((cycle) => (
                    <option key={cycle.id} value={cycle.id}>
                      {`${this.formatLabel(
                        cycle.endTimestamp
                      )} - ${companyName}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3 mb-2">
                <select
                  className="custom-select"
                  disabled={payrollCycles.length === 0}
                  value={behaviour}
                  onChange={(e) => this.setState({ behaviour: e.target.value })}
                >
                  <option defaultValue>Select behaviours</option>
                  {behaviours.map((behaviourItem) => (
                    <option key={behaviourItem} value={behaviourItem}>
                      {behaviourItem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3 mb-2">
                <select
                  className="custom-select"
                  disabled={payrollCycles.length === 0}
                  value={category}
                  onChange={(e) => this.setState({ category: e.target.value })}
                >
                  <option defaultValue>Select category</option>
                  {categories.map((categoryItem) => (
                    <option key={categoryItem} value={categoryItem}>
                      {categoryItem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3 mb-2">
                <div className="input-group">
                  <input
                    required
                    disabled={payrollCycles.length === 0}
                    type="number"
                    className="form-control"
                    placeholder="amount"
                    value={amount}
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => this.setState({ amount: e.target.value })}
                  />
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <Grid item container xs={12} className={row}>
                <Grid item container xs={2} className={row}>
                  <Typography variant="body2">Date:</Typography>
                </Grid>

                <Grid item container xs={8} className={row}>
                  <CobeeDatePicker
                    disabled={!payrollId}
                    placeholderText="Select date"
                    selectedDate={selectedDate}
                    selected={selectedDate ? new Date(selectedDate) : null}
                    showTimeSelect
                    maxDate={
                      this.getPickerDates(startTimestamp, endTimestamp).maxDate
                    }
                    minDate={
                      this.getPickerDates(startTimestamp, endTimestamp).minDate
                    }
                    timeIntervals={15}
                    onChange={(e) => {
                      this.setState({ selectedDate: e });
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="form-group row">
              <div className="col-sm-12">
                <input
                  name="concept"
                  disabled={payrollCycles.length === 0}
                  className="form-control"
                  placeholder="Concept (Optional)"
                  value={concept}
                  onChange={(e) => this.setState({ concept: e.target.value })}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-6 mb-2">
                <select
                  className="custom-select"
                  value={employeeId}
                  disabled={payrollCycles.length === 0}
                  onChange={(e) => {
                    this.setState({ employeeId: e.target.value });
                    this.loadPayrollCycles(e.target.value);
                  }}
                >
                  <option defaultValue>Select id</option>
                  {employees.map(
                    ({ id, name, surname, companyName: company, state }) => (
                      <option key={id} value={id}>
                        {name} {surname} - {state} - {id} - {company}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="col-sm-3 mb-2">
                <input
                  type="password"
                  className="form-control"
                  aria-label="Password"
                  placeholder="Password"
                  disabled={payrollCycles.length === 0}
                  aria-describedby="inputGroup-sizing-default"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <button
                  className="btn btn-primary mb-2"
                  disabled={
                    !selectedDate ||
                    !payrollId ||
                    !behaviour ||
                    !category ||
                    !employeeId ||
                    !password ||
                    loading
                  }
                  onClick={() => this.executeDelivery()}
                >
                  Execute Delivery
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(DeliveriesSection);
